import { v4 as uuidv4 } from 'uuid';

const state = {
  alerts: [],
  alert: {
    type: "",
    message: "",
    shown: false,
    constant: false,
    buttonLink: null,
  }
};

const getters = {
  getAlerts(state) {
    return state.alerts
  }
};

const actions = {
  addAlert({commit}, data) {
    data.uuid = uuidv4();
    commit('ADD_ALERT', data);
  },
  removeAlert({commit}, uuid) {
    let alertIndex = state.alerts.findIndex((alert) => alert.uuid === uuid)
    commit('REMOVE_ALERT', alertIndex)
  },
};

const mutations = {
  ADD_ALERT(state, payload) {
    state.alerts.unshift(payload);
  },
  REMOVE_ALERT(state, i) {
    state.alerts.splice(i, 1)
  }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
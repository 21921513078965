import axios from "axios";
import {API_LOGIN, API_INVITE, API_SELF, API_GET_MEMBERS, API_GET_MEMBER_STATUSES, API_GET_SECTIONS, API_UPDATE_MEMBER, API_ADD_MEMBER} from "@/constants";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
let token = window.localStorage.getItem("token")
if (token) {
    axios.defaults.headers["Authorization"] = token;
}
export default {
    reinitializeToken() {
        let token = window.localStorage.getItem("token")
        if (token) {
            axios.defaults.headers["Authorization"] = token;
        }
    },
    login(data) {
        return axios.post(API_LOGIN, data);
    },
    acceptInvite(data) {
        return axios.put(API_INVITE, data)
    },
    me(token) {
        return axios.get(API_SELF, {
            headers: {
                "Authorization": token
            }
        });
    },
    invite(data) {
        return axios.post(API_INVITE, data)
    },
    getMembers(query) {
        return axios.get(API_GET_MEMBERS, {params: query});
    },
    updateMember(data) {
        return axios.patch(API_UPDATE_MEMBER, data);
    },
    getMemberStatuses() {
        return axios.get(API_GET_MEMBER_STATUSES);
    },
    getSections() {
        return axios.get(API_GET_SECTIONS);
    },
    addMember(data) {
        return axios.post(API_ADD_MEMBER, data);
    }
}

import Api from '@/services/Api';

const state = {
    members: [],
    pagingData: {},
    selectedMember: {},
    loadingMembers: false,
    statuses: [],
    query: {}
};

const getters = {
    members(state) {
        return state.members;
    },
    statuses(state) {
        return state.statuses;
    },
    query(state) {
        return state.query;
    },
    pagingData(state) {
        return state.pagingData;
    },
    loadingMembers(state) {
        return state.loadingMembers;
    },
    selectedMember(state) {
        return state.selectedMember;
    }
};

const actions = {
    get({ commit }, query) {
        return new Promise((resolve, reject) => {
            commit('SET_CURRENT_QUERY', query)
            commit('LOADING_MEMBERS', true);
            Api.getMembers(state.query).then(response => {
                commit('SET_MEMBERS', response.data.members)
                let pagingData = {
                    currentPage: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    totalItems: response.data.totalItems
                }
                commit('SET_PAGING_DATA', pagingData)
                commit('LOADING_MEMBERS', false);
                resolve();
            }).catch(err => {
                commit('LOADING_MEMBERS', false);
                reject(err);
            });
        });
    },
    updateMember({commit}, data) {
        return new Promise((resolve, reject) => {
            Api.updateMember(data).then(response => {
                commit('SET_SELECTED_MEMBER', response.data)
                commit('UPDATE_MEMBER', response.data)
                resolve();
            }).catch(err => {
                reject(err);

            })
        })
    },
    addMember({commit}, data) {
        return new Promise((resolve, reject) => {
            Api.addMember(data).then(response => {
                commit('ADD_MEMBER', response.data);
                resolve(response.data);
            }).catch(err => {
                reject(err);
            })
        })
    },
    getStatuses({ commit }) {
        return new Promise((resolve, reject) => {
            Api.getMemberStatuses().then(response => {
                commit('SET_MEMBER_STATUSES', response.data.statuses)
                resolve();
            }).catch(err => {
                reject(err);
            })
        })
    },
    select({ commit }, data) {
        commit('SET_SELECTED_MEMBER', data);
    }
};

const mutations = {
    SET_MEMBERS(state, data) {
        state.members = data;
    },
    SET_CURRENT_QUERY(state, query) {
        state.query = query;
    },
    SET_PAGING_DATA(state, data) {
        state.pagingData = data;
    },
    LOADING_MEMBERS(state, value) {
        state.loadingMembers = value;
    },
    SET_SELECTED_MEMBER(state, value) {
        state.selectedMember = value;
    },
    SET_MEMBER_STATUSES(state, value) {
        state.statuses = value;
    },
    UPDATE_MEMBER(state, value) {
        state.members = [
            ...state.members.filter((member) => member.id !== value.id), value
        ];
    },
    ADD_MEMBER(state, value) {
        state.members.unshift(value);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

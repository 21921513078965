import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user.store';
import global from './modules/global.store';
import members from './modules/members.store';
import section from './modules/section.store';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    global,
    members,
    section
  },
})

<template>
  <v-app>
    <v-main>
      <Alert></Alert>
      <sidebar-menu v-if="['Login', 'Accept Invite'].indexOf($route.name) === -1"></sidebar-menu>
      <p class="mt-4 ml-4 font-weight-medium" v-if="['Login', 'Accept Invite'].indexOf($route.name) === -1">{{$route.name}}</p>
      <v-divider v-if="['Login', 'Accept Invite'].indexOf($route.name) === -1"></v-divider>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import SidebarMenu from '@/components/SidebarMenu'
import Alert from '@/components/Alert'
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'App',
  components: {
    SidebarMenu,
    Alert
  },
  computed: {
    ...mapGetters({
      "token": "user/token"
    })
  },
  methods: {
    ...mapActions({
      "getSelf": "user/getSelf"
    })
  },

  data: () => ({
    //
  }),
};
</script>

<style>

.alert--bottom-fixed {
  width: 100%;
  z-index: 999;
  bottom: 0px;
  position: fixed !important;
}
</style>

import Api from '@/services/Api';
import router from '../../router';
const state = {
    user: {},
    token: window.localStorage.getItem("token") || "",
    loggingIn: false,
    acceptingInvite: false,
    inviting: false,
};

const getters = {
    user(state) {
        return state.user
    },
    token(state) {
        return state.token
    },
    loggingIn(state) {
        return state.loggingIn
    },
    acceptingInvite(state) {
        return state.acceptingInvite
    }
};

const actions = {
    login({ commit }, data) {
        return new Promise((resolve, reject) => {
            commit('LOGGING_IN', true)
            Api.login(data).then(response => {
                window.localStorage.setItem("token", response.data.token);
                commit('SET_USER', response.data.user)
                commit('SET_TOKEN', response.data.token)
                commit('LOGGING_IN', false);
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    },
    get({ commit }, query) {
        return new Promise((resolve, reject) => {
            commit('SET_CURRENT_QUERY', query)
            commit('LOADING_MEMBERS', true);
            Api.getMembers(state.query).then(response => {
                commit('SET_MEMBERS', response.data.members)
                let pagingData = {
                    currentPage: response.data.currentPage,
                    totalPages: response.data.totalPages,
                    totalItems: response.data.totalItems
                }
                commit('SET_PAGING_DATA', pagingData)
                commit('LOADING_MEMBERS', false);
                resolve();
            }).catch(err => {
                commit('LOADING_MEMBERS', false);
                reject(err);
            });
        });
    },
    acceptInvite({ commit }, data) {
        commit('ACCEPTING_INVITE', true)
        return new Promise((resolve, reject) => {
            Api.acceptInvite(data).then(() => {
                resolve();
            }).catch(err => {
                reject(err);
            }).finally(() => {
                commit('ACCEPTING_INVITE', false)
            })
        });
    },
    getSelf({ commit, state },) {
        return new Promise((resolve, reject) => {
            Api.me(state.token).then(response => {
                commit('SET_USER', response.data.user);
            }).catch(err => {
                if (err.response.status === 401) {
                    window.localStorage.removeItem("token");
                }
                reject(err);
            });
        })
    },
    invite({commit}, data) {
        return new Promise((resolve, reject) => {
            commit('INVITING', true);
            Api.invite(data).then(() => {
                resolve();
            }).catch(err => {
                reject(err);
            }).finally(() => {
                commit('INVITING', false);
            });
        });
    },
    logout({ commit}) {
        window.localStorage.removeItem("token");
        commit('SET_USER', null);
        commit('SET_TOKEN', null);
        router.replace('/login');
    }
};

const mutations = {
    LOGGING_IN(state, value) {
        state.loggingIn = value;
    },
    SET_USER(state, data) {
        state.user = data;
    },
    SET_TOKEN(state, token) {
        state.token = token;
    },
    ACCEPTING_INVITE(state, value) {
        state.acceptingInvite = value;
    },
    INVITING(state, value) {
        state.inviting = value;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

<template>
  <v-card>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
      class="d-flex justify-space-between"
    >
      <div>

      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="../assets/avatar.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>{{user.firstName}} {{user.lastName}}</v-list-item-title>

        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      </div>
      <div>
        <v-list dense>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Odjava</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
  export default {
    computed: {
      ...mapGetters({
        "user": "user/user"
      })
    },
    methods: {
      ...mapActions({
        "logoutUser": "user/logout"
      }),
      logout() {
        this.logoutUser()
      }
    },

    data: () => ({
        drawer: true,
        items: [
          { title: 'Statistika', icon: 'mdi-home-analytics', route: '/' },
          { title: 'Popis lovaca', icon: 'mdi-account-multiple', route: '/members' },
          { title: 'Blagajna', icon: 'mdi-piggy-bank', route: '/bank' },
          { title: 'Korisnici', icon: 'mdi-account-convert', route: '/users'}
        ],
        mini: true,
      }),
  }
</script>

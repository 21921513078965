import Api from '@/services/Api';

const state = {
    sections: [],
};

const getters = {
    sections(state) {
        return state.sections;
    },
};

const actions = {
    get({ commit }) {
        return new Promise((resolve, reject) => {
            Api.getSections().then(response => {
                commit('SET_SECTIONS', response.data.sections)
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    },
};

const mutations = {
    SET_SECTIONS(state, data) {
        state.sections = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
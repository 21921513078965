import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Statistika',
    redirect: '/members'
    // component: Home
  },
  {
    path: '/members',
    name: 'Popis članova',
    component: () => import(/* webpackChunkName: "members" */ '../views/Members.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/accept-invite',
    name: 'Accept Invite',
    component: () => import(/* webpackChunkName: "accept-invite" */ '../views/AcceptInvite.vue')
  },
  {
    path: '/users',
    name: 'Korisnici',
    component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
